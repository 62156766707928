<template>
    <b-modal
      v-model="data.showModal"
      size="xl"
      button-size="sm"
      modal-class="template-modal"
      title-class="w-full"
      footer-class="w-full"
      no-fade
      @show="onShow"
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
    >
      <!-- Modal header -->
      <div slot="modal-title">
        <div class="flex w-full justify-between">
          Label Editor
        </div>
      </div>

      <!-- Modal content -->
      <div class="flex flex-col gap-2">
        <div class="d-flex flex-column gap-3">
          <div v-if="label" class="d-flex flex-column gap-3">

            <div class="flex justify-between gap-4">
              <!-- Base form -->
              <div class="flex-1 flex flex-col gap-4">
                <div>
                  <h5 :class="{ 'invalid-class': !valid.properties }">Properties</h5>
                  <properties-form :label="label" />
                </div>

                <div class="d-flex flex-column gap-2">
                  <div class="d-flex justify-content-between">
                    <h5 :class="{ 'invalid-class': !valid.fields }">Fields</h5>
                    <div>
                      <b-button
                        variant="primary"
                        size="sm"
                        :disabled="!canCreateField"
                        @click="createField"
                      >
                        Create field
                      </b-button>
                    </div>
                  </div>

                  <fields-display
                    v-model="displayField"
                    :fields="label.fields"
                  />
                  <h6 v-if="label.fields.length === 0">No fields available</h6>
                </div>

                <div class="flex justify-center">
                  <label-preview :label="label" />
                </div>
              </div>

              <!-- Field editor -->
              <div class="flex-1">
                <div
                  v-if="displayField"
                  class="d-flex flex-column gap-2"
                  :key="`displayField_${displayField.id}`"
                >
                  <h5 :class="{ 'invalid-class': !valid.display_field }">
                    Field - {{ displayField.field_name }}
                  </h5>
                  <field-form
                    :field="displayField"
                    :selectGroups="selectGroups"
                    @delete="deleteField(displayField.id)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-else>Loading label..</div>
        </div>
      </div>

      <!-- Modal footer -->
      <div slot="modal-footer" class="w-full d-flex gap-4 justify-content-between items-center">
        <div class="flex w-full justify-content-between">
          <div>
            <b-btn
              size="sm"
              variant="primary"
              :disabled="isLoading || !canSave"
              @click="onSave"
            >
              Save
            </b-btn>
          </div>
          <div class="flex gap-2">
            <b-btn
              variant="danger"
              size="sm"
              @click="deleteLabel"
            >
              Delete
            </b-btn>

            <b-btn
              size="sm"
              variant="white"
              :disabled="isLoading"
              @click="onClose"
            >
              Close
            </b-btn>
          </div>
        </div>
      </div>
    </b-modal>
  </template>

<script>
import {
  validateLabel,
  validateLabelProperties,
  validateLabelField,
  validateLabelFields,
} from '@/helpers';
import { v4 as uuidv4 } from 'uuid';

const LabelPreview = () => import('@/components/labels/LabelPreview.vue');
const PropertiesForm = () => import('@/components/labels/PropertiesForm.vue');
const FieldsDisplay = () => import('@/components/labels/FieldsDisplay.vue');
const FieldForm = () => import('@/components/labels/FieldForm.vue');

export default {
  name: 'LabelEditor',
  components: {
    LabelPreview,
    PropertiesForm,
    FieldsDisplay,
    FieldForm,
  },
  props: {
    data: Object,
    selectGroups: Array,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    canSave() {
      if (this.isLoading) {
        return false;
      }
      if (!validateLabel(this.label)) {
        return false;
      }
      return true;
    },
    valid() {
      return {
        properties: validateLabelProperties(this.label),
        fields: validateLabelFields(this.label),
        display_field: this.displayField ? validateLabelField(this.displayField) : true,
      };
    },
    canCreateField() {
      return this.label !== null;
    },
  },
  data() {
    return {
      label: null,
      labelError: false,
      preview: null,
      displayField: null,
      loadingCount: 0,
      filters: {},
      options: {},
    };
  },
  methods: {
    fetchAll() {
      this.fetchLabel();
    },
    onSave() {
      this.updateLabel();
    },
    onShow() {
      this.fetchAll();
    },
    onClose() {
      this.data.showModal = false;
      this.$emit('close');
    },
    createField() {
      const fieldRow = (
        this.label.fields.length > 0
          ? Math.max(...this.label.fields.map((field) => field.field_row)) + 1
          : 0
      );
      const newField = {
        id: uuidv4(),
        field_key: '',
        field_row: fieldRow,
        field_column: 0,
        field_name: '',
        field_label: true,
        field_dots: true,
        select_group_key: '',
        text_type: 'text',
        text_content: '',
        text_prefix: '',
        text_suffix: '',
        text_direction: 'ltr',
      };
      this.label.fields.push(newField);
      this.displayField = newField;
    },
    deleteField(id) {
      this.label.fields = this.label.fields.filter((field) => field.id !== id);
      this.displayField = null;
    },
    updateLabel() {
      if (!validateLabel(this.label)) {
        return;
      }
      this.loadingCount++;
      this.$http
        .post(`/labels/${this.label.id}`)
        .send({ label: this.label })
        .then(() => {
          this.$emit('updated', this.label.id);
          this.onClose();
        })
        .catch((err) => {
          alert(`Failed to update label: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchLabel() {
      this.loadingCount++;
      this.$http
        .get(`/labels/${this.data.labelId}`)
        .then((res) => {
          this.label = res.body;
        })
        .catch((err) => {
          alert(`Failed to fetch label: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    deleteLabel() {
      if (!confirm(`Do you really wish to delete label "${this.label.label_name.length ? this.label.label_name : this.label.id}"?`)) {
        return;
      }
      this.loadingCount++;
      this.$http
        .delete(`/labels/${this.label.id}`)
        .then(() => {
          this.$emit('deleted', this.label.id);
          this.onClose();
        })
        .catch((err) => {
          this.$toast.error(`Failed to delete label: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
  },
};
</script>

<style lang="scss">
</style>
